import React from "react";
import styled from "@emotion/styled";
import { MDXProvider } from "@mdx-js/react";
import MdxLink from "./mdx-link";
import { colors } from "../../style/theme";
import useLocale from "../../hooks/useLocale";

export default function FullWidth({ children, maxWidth }) {
  const { isAra } = useLocale();
  return (
    <div
      css={{
        position: "relative",
        left: isAra ? "auto" : "50%",
        right: isAra ? "50%" : "auto",
        transform: isAra ? " translateX(50%)" : "translateX(-50%)",
        width: "100vw",
        maxWidth: maxWidth ? maxWidth : "none",
        backgroundColor: colors.black,
        marginTop: "2rem",
        marginBottom: "2rem",
        padding: "2rem",
        lineHeight: "26px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <MDXProvider>{children}</MDXProvider>
    </div>
  );
}

import React from "react";
import useTranslation from "../../hooks/useTranslations";
import { MdKeyboardArrowDown } from "react-icons/md";
import { colors } from "../../style/theme";

export function ReadMore({ to }) {
  const tr = useTranslation();
  return (
    <div
      css={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "5rem",
        cursor: "pointer",
      }}
    >
      <a
        css={{
          display: "block",
          color: colors.secondary,
          border: `1px solid ${colors.secondary}`,
          fontSize: "20px",
          padding: "0.3rem",
          paddingBottom: 0,
          ":hover": {
            color: colors.secondary,
          },
        }}
        href={to}
      >
        {tr("Read More")} <MdKeyboardArrowDown />
      </a>
    </div>
  );
}

import React from "react";
import { HorizontalBar } from "react-chartjs-2";

const data = {
  labels: [
    "Coalition Forces",
    "Combined Syrian and Russian Forces",
    "ISIS",
    "The YPG/ PYD -SDF",
    "Opposition groups",
    "Perpetrator Contested",
    "Perpetrator Unknown",
    "Pro-Government Militias",
    "Russian Forces",
    "Syrian Armed Forces",
    "Syrian or Russian Forces",
    "Turkish Forces",
  ],
  datasets: [
    {
      label: "# of incidents",
      data: [2, 4, 1, 2, 17, 3, 127, 4, 64, 117, 67, 2],
      backgroundColor: [
        "rgba(201, 117, 76, 0.2)",
        "rgba(201, 117, 76, 0.2)",
        "rgba(201, 117, 76, 0.2)",
        "rgba(217, 156, 127, 0.2)",
        "rgba(217, 156, 127, 0.2)",
        "rgba(217, 156, 127, 0.2)",
        "rgba(217, 156, 127, 0.2)",
        "rgba(217, 156, 127, 0.2)",
        "rgba(217, 156, 127, 0.2)",
        "rgba(217, 156, 127, 0.2)",
        "rgba(217, 156, 127, 0.2)",
        "rgba(217, 156, 127, 0.2)",
      ],
      borderColor: [
        "rgba(179, 58, 0, 1)",
        "rgba(179, 58, 0, 1)",
        "rgba(179, 58, 0, 1)",
        "rgba(179, 58, 0, 1)",
        "rgba(179, 58, 0, 1)",
        "rgba(179, 58, 0, 1)",
        "rgba(179, 58, 0, 1)",
        "rgba(179, 58, 0, 1)",
        "rgba(179, 58, 0, 1)",
        "rgba(179, 58, 0, 1)",
        "rgba(179, 58, 0, 1)",
        "rgba(179, 58, 0, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

const legendOpts = {
  labels: {
    fontColor: "rgb(255, 255, 255)",
  },
};

const options = {
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
          fontColor: "white",
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          fontColor: "white",
        },
      },
    ],
  },
};

const AllegedPerpetratorsChart = () => (
  <div
    css={{
      position: "relative",
      zIndex: 3,
      backgroundColor: "black",
      marginTop: "2rem",
      marginBottom: "2rem",
    }}
  >
    <HorizontalBar data={data} options={options} legend={legendOpts} />
  </div>
);

export default AllegedPerpetratorsChart;

import React from "react";
import styled from "@emotion/styled";
import { IoWarning } from "react-icons/io5";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import MdxLink from "./mdx-link";
import { colors } from "../../style/theme";

export default function Warning({ children, title, icon }) {
  const showIcon = icon === true;
  return (
    <div
      css={{
        marginTop: "2rem",
        marginBottom: "2rem",
        backgroundColor: colors.secondary,
        color: colors.black,
        padding: "2rem",
        lineHeight: "26px",
      }}
    >
      <h3 css={{ textAlign: "center" }}>
        {showIcon && <IoWarning />} {title}
      </h3>
      <MDXProvider
        components={{
          a: styled(MdxLink)({
            color: colors.black,
            fontWeight: "bold",
            ":hover": {
              color: colors.black,
            },
          }),
        }}
      >
        {children}
      </MDXProvider>
    </div>
  );
}

import styled from "@emotion/styled";
import { mq } from "../utils/helper";

export const colors = {
  primary: "#b33a00",
  secondary: "#FF5400",
  gray: "#f2f2f2",
  light: "#fff",
  backgroundColor: "#f5f5f5",
  black: "#000",
  chart01: "#2F5C65",
  chart02: "#138BBF",
  chart03: "#310C25",
  chart04: "#003313",
  chart05: "#CAA900",
};

export const SelectStyle = {
  container: (provided) => ({
    ...provided,
    maxWidth: "20rem",
    width: "100%",
    marginBottom: "1rem",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

export const KeyValue = styled.div({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "0.2rem",
  //  maxWidth: "700px",
});

export const Key = styled.h6({
  margin: 0,
  marginTop: "0.4rem",
  minWidth: "200px",
  width: "200px",
  maxWidth: "200px",
});

export const Value = styled.p({
  margin: 0,
  marginRight: "auto",
  marginLeft: "6rem",
  textAlign: "left",
});

export const Link = styled.a({
  color: colors.primary,
  ":hover": {
    color: colors.primary,
  },
});

export const SortOption = styled.span({
  paddingRight: "0.6rem",
  paddingLeft: "0.6rem",
  cursor: "pointer",
  [mq[1]]: {
    padding: 0,
  },
});

export const DataContainer = styled.div({
  backgroundColor: colors.backgroundColor,
  display: "grid",
  gridTemplateColumns: "repeat(2, minmax(20px, 1fr))",
  gridColumnGap: "2rem",
  maxWidth: "1200px",
  margin: "auto",
  [mq[0]]: {
    display: "block",
  },
});

const datePickerStyle = {
  display: "block",
  width: "100%",
  height: "2.5rem",
  padding: ".65rem 1rem",
  fontSize: "1rem",
  fontWeight: "400",
  lineHeight: "1.5",
  color: "#3f4254",
  backgroundColor: "#fff",
  backgroundClip: "padding-box",
  border: "1px solid #ccc",
  borderRadius: ".42rem",
  boxShadow: "none",
  transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  "&:focus": {
    outline: `3px solid #2684ff`,
    border: `3px solid #2684ff`,
  },
};

const datePickerStyleMap = {
  ...datePickerStyle,
  marginBottom: "1rem",
};

const datePickerStyleTable = {
  ...datePickerStyle,
  width: "90%",
  marginRight: "2rem",
};

export const dataFilters = {
  outerDiv: {},
  innerDiv: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "80%",
  },
  select: {
    container: (provided) => ({
      ...provided,
      maxWidth: "13rem",
      width: "100%",
      marginBottom: "1rem",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  },
  search: { maxWidth: "20rem" },
  switch: { display: "none" },
  break: { flexBasis: "100%", height: 0 },
  datePicker: datePickerStyleTable,
};

export const mapFilters = {
  outerDiv: { borderRight: `1px solid silver` },
  innerDiv: {
    width: "17rem",
    margin: "2rem",
    padding: "1rem",
    backgroundColor: colors.light,
    height: "80vh",
  },
  search: { width: "100%", marginBottom: "1rem", height: "40px" },
  select: {
    container: (provided) => ({
      ...provided,
      maxWidth: "20rem",
      width: "100%",
      marginBottom: "1rem",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  },
  switch: { display: "flex", justifyContent: "center", marginTop: "2rem" },
  break: {},
  datePicker: datePickerStyleMap,
};

export const OutLine = styled.div({
  fontSize: "20px",
  borderBottom: "4px solid #B33A00",
  paddingBottom: "1rem",
  marginBottom: "2rem",
});

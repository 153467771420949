import React from "react";
import useIncidents from "../../hooks/useIncidents";
import useLocale from "../../hooks/useLocale";
import Timeline from "../../charts/timeline";

export default function TimelineMdx() {
  const { incidents } = useIncidents();
  const { locale } = useLocale();
  return (
    <Timeline
      lang={locale}
      incidents={incidents}
      width={600}
      height={100}
      isLoading={false}
    />
  );
}

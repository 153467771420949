import { colors } from "../../../../src/style/theme.js";
import { ReadMore } from "../../../../src/components/mdx/key-finding.js";
import Warning from "../../../../src/components/mdx/warning.js";
import Box from "../../../../src/components/mdx/box.js";
import FullWidth from "../../../../src/components/mdx/full-width.js";
import AllegedPerpetratorsChart from "../../../../src/charts/alleged-perpetrators.js";
import UnlawfulWeaponsChart from "../../../../src/charts/unlawful-weapons.js";
import Album from "../../../../src/components/mdx/album.js";
import TimelineMdx from "../../../../src/components/mdx/timeline.js";
import Video from "../../../../src/components/video.js";
import ReactPlayer from "react-player/lazy";
import * as React from 'react';
export default {
  colors,
  ReadMore,
  Warning,
  Box,
  FullWidth,
  AllegedPerpetratorsChart,
  UnlawfulWeaponsChart,
  Album,
  TimelineMdx,
  Video,
  ReactPlayer,
  React
};
import { useState, useEffect, useMemo } from "react";
//import Fuse from "fuse.js";
import { useStaticQuery, graphql } from "gatsby";
import {
  allPass,
  equals,
  filter,
  includes,
  isEmpty,
  where,
  isNil,
  anyPass,
  propSatisfies,
  or,
} from "ramda";
import { parse, format } from "date-fns";
import { isBefore, isAfter } from "date-fns/fp";

export const defaultFilters = {
  location: null,
  deliveryMethod: null,
  typeOfMunition: null,
  allegedPerpetrator: null,
  areaOfControl: null,
  isDouble: null,
  isDirectHit: null,
  isMultiple: null,
  isPreConflict: null,
  hasCasualties: null,
  title: null,
  fromDate: null,
  toDate: null,
  isRemoteLocation: null,
  filterType: "all",
};

export default function useIncidents() {
  const data = useStaticQuery(graphql`
    query Data {
      allIncidentsJson {
        edges {
          node {
            observations {
              link
            }
            pre_conflict
            is_double
            alleged_perpetrator
            area_of_control
            date
            delivery_method
            has_casualties
            is_remote_location
            hospital_ar
            hospital_en
            is_multiple
            title_en
            title_ar
            id
            incident_code
            is_direct_hit
            lat
            location
            long
            munition
          }
        }
      }
    }
  `);
  const allIncidents = data.allIncidentsJson.edges.map((o) => ({
    ...o.node,
    formattedDate: format(new Date(o.node.date), "yyyy-MM-dd"),
    date: new Date(o.node.date),
  }));

  const [incidents, setIncidents] = useState(allIncidents);
  const [filters, setFilters] = useState(defaultFilters);
  const [searchQuery, setSearchQuery] = useState("");

  useMemo(() => {
    const inLocation = where({ location: equals(filters.location) });
    const withDeliveryMethod = where({
      delivery_method: equals(filters.deliveryMethod),
    });
    const hasTypeOfMunition = where({
      munition: equals(filters.typeOfMunition),
    });
    const withAllegedPerpetrator = where({
      alleged_perpetrator: equals(filters.allegedPerpetrator),
    });
    const underAreaOfControl = where({
      area_of_control: equals(filters.areaOfControl),
    });
    const byGovernmentish = propSatisfies(
      (x) =>
        or(
          equals(x, "Syrian Armed Forces"),
          equals(x, "Russian Forces"),
          equals(x, "Syrian or Russian Forces")
        ),
      "alleged_perpetrator"
    );
    const isDirectHit = where({ is_direct_hit: equals(filters.isDirectHit) });
    const isDouble = where({ is_double: equals(filters.isDouble) });
    const isRemoteLocation = where({
      is_remote_location: equals(filters.isRemoteLocation),
    });
    const isMultiple = where({ is_multiple: equals(filters.isMultiple) });
    const isPreConflict = where({
      pre_conflict: equals(filters.isPreConflict),
    });
    const hasCasualites = where({
      has_casualties: equals(filters.hasCasualties),
    });
    const hasTitle = where({ title_en: includes(filters.title) });
    const afterDate = where({ date: isAfter(filters.fromDate) });
    const beforeDate = where({ date: isBefore(filters.toDate) });

    const listOfPredicates = [];
    !isNil(filters.location) && listOfPredicates.push(inLocation);
    !isNil(filters.deliveryMethod) && listOfPredicates.push(withDeliveryMethod);
    !isNil(filters.typeOfMunition) && listOfPredicates.push(hasTypeOfMunition);
    !isNil(filters.allegedPerpetrator) &&
      listOfPredicates.push(withAllegedPerpetrator);
    !isNil(filters.areaOfControl) && listOfPredicates.push(underAreaOfControl);
    !isNil(filters.isDirectHit) && listOfPredicates.push(isDirectHit);
    !isNil(filters.isByGovernmentish) && listOfPredicates.push(byGovernmentish);
    !isNil(filters.isDouble) && listOfPredicates.push(isDouble);
    !isNil(filters.isMultiple) && listOfPredicates.push(isMultiple);
    !isNil(filters.isRemoteLocation) && listOfPredicates.push(isRemoteLocation);
    !isNil(filters.isPreConflict) && listOfPredicates.push(isPreConflict);
    !isNil(filters.hasCasualties) && listOfPredicates.push(hasCasualites);
    !isNil(filters.title) && listOfPredicates.push(hasTitle);
    !isNil(filters.fromDate) && listOfPredicates.push(afterDate);
    !isNil(filters.toDate) && listOfPredicates.push(beforeDate);

    const allPassedIncidents = allPass(listOfPredicates);
    const anyPassedIncidents = anyPass(listOfPredicates);

    const filteredIncidents =
      filters.filterType === "all" ? allPassedIncidents : anyPassedIncidents;

    setIncidents(filter(filteredIncidents, allIncidents));
  }, [filters]);

  return { incidents, setFilters, filters, setSearchQuery };
}

import React from "react";
import styled from "@emotion/styled";
import { IoWarning } from "react-icons/io5";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import MdxLink from "./mdx-link";
import { colors } from "../../style/theme";

export default function Box({ children, title, icon }) {
  const showIcon = icon === true;
  return (
    <div
      css={{
        border: `1px solid ${colors.secondary}`,
        marginTop: "2rem",
        marginBottom: "2rem",
        padding: "2rem",
        lineHeight: "26px",
      }}
    >
      <h5 css={{ textAlign: "center" }}>
        {showIcon && <IoWarning />} {title}
      </h5>
      <MDXProvider
        components={{
          a: styled(MdxLink)({
            color: colors.primary,
            fontWeight: "bold",
            ":hover": {
              color: colors.primary,
            },
          }),
        }}
      >
        {children}
      </MDXProvider>
    </div>
  );
}
